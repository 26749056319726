import { responseHandler } from './responseHandler';
import request from './request';

export const sendMessage = ({ phone, message }) => {
  const url = `${process.env.MARKETING_URL}/v2/video/invite`;
  return request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ap-stage': 'staging',
    },
    body: JSON.stringify({ phone, message }),
  }).then(responseHandler);
};

export const getShortenUrl = ({ url, expiration }) => {
  const URL = `${process.env.MARKETING_URL}/v2/video/urlshortener`;
  return request(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ap-stage': 'staging',
    },
    body: JSON.stringify({ url, expiration }),
  }).then(responseHandler);
};
