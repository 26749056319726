import React, { useState, useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '../../components/1TalkDesign/Buttons';
import Toast from '../../components/1TalkDesign/Toast';
import { copy } from '../../utils/copy';
import { Button as MatButton } from '@material-ui/core';
import Layout from '../../components/layout';
import { useDeviceDetect } from '../../utils/device';
import MessageSender from '../../components/room/MessageSender';
import { sendMessage, getShortenUrl } from '../../services/client.service';
import { t } from 'src/i18n/config';

const Container = styled.div``;

const Description = styled.div`
  margin-top: 12px;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.3px;
  text-align: start;
  color: ${(props) => props.theme.colors.textSecondary};
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
`;

const URLSection = styled.div`
  flex-grow: 1;
  border: solid 1px rgba(0, 0, 0, 0.23);
  border-right: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
`;

const URLSectionHint = styled.div`
  flex-grow: 1;
  color: grey;
  background-color: #e8eaf1;
  border-right: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-family: NotoSansCJKtc;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.54);
  padding-left: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
`;

const URLSectionMobile = styled(URLSection)`
  flex-grow: unset;
  border: solid 1px rgba(0, 0, 0, 0.23);
  padding-left: 16px;
  display: flex;
  align-items: center;
  margin-top: 24px;

  border-radius: 4px;
  padding: 10px;
`;

const URLSectionHintMobile = styled(URLSectionHint)`
  flex-grow: unset;
  margin-top: 24px;
  border-radius: 4px;
  padding: 10px;
`;

const GenerateURLButton = styled(Button)`
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  flex: none;
`;

const MButton = styled(MatButton)`
  width: 130px;
`;
const MButtonMobile = styled(MatButton)`
  width: 100%;
`;

const GenerateURLMobileButton = styled(Button)`
  border-radius: 4px;
  width: 100%;
  margin-top: 16px;
`;

const Loading = styled(CircularProgress)`
  color: #d3d3d3;
`;

const MenuTitle = styled.div`
  margin-top: 24px;
  font-size: 1.625rem;
  line-height: 1.6;
  letter-spacing: 0.08px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.textPrimary};
`;

const A = styled.a`
  color: inherit;
`;

const STATE = {
  INITIAL: 0,
  REQUESTING: 1,
  SUCCESS: 2,
  FAILED: 3,
};

const renderURLSection = (state, isMobile) => {
  switch (state.progress) {
    case STATE.SUCCESS: {
      const url = state.url;
      return isMobile ? (
        <URLSectionMobile>{url}</URLSectionMobile>
      ) : (
        <URLSection>{url}</URLSection>
      );
    }
    case STATE.FAILED: {
      const msg = state.message;
      return isMobile ? (
        <URLSectionMobile>{msg}</URLSectionMobile>
      ) : (
        <URLSection>{msg}</URLSection>
      );
    }
    default: {
      const term = t('issuedUrl');
      return isMobile ? (
        <URLSectionHintMobile>{term}</URLSectionHintMobile>
      ) : (
        <URLSectionHint>{term}</URLSectionHint>
      );
    }
  }
};
const renderActions = (state, onClickButton, onClickResetButton, isMobile) => {
  let title = t('issueUrl');
  switch (state.progress) {
    case STATE.SUCCESS:
      title = t('copy');
      break;
    case STATE.REQUESTING:
      title = t('copy');
      title = <Loading size={23}></Loading>;
      break;
    case STATE.FAILED:
      title = t('tryAgain');
      break;
    default:
      break;
  }
  if (isMobile) {
    return (
      <div>
        {renderURLSection(state, isMobile)}
        {state.progress === STATE.SUCCESS && (
          <MButtonMobile
            disableElevation
            variant="outlined"
            onClick={onClickResetButton}
          >
            {t('regenerate')}
          </MButtonMobile>
        )}
        <GenerateURLMobileButton onClick={onClickButton}>
          {title}
        </GenerateURLMobileButton>
      </div>
    );
  }
  return (
    <Actions>
      {renderURLSection(state)}
      {state.progress === STATE.SUCCESS && (
        <MButton
          disableElevation
          variant="outlined"
          onClick={onClickResetButton}
        >
          {t('regenerate')}
        </MButton>
      )}
      <GenerateURLButton
        disableElevation
        variant="contained"
        onClick={onClickButton}
      >
        {title}
      </GenerateURLButton>
    </Actions>
  );
};

const toastDefualtState = { open: false };
const toastReducer = (state, action) => {
  switch (action.type) {
    case 'open':
      return {
        open: true,
        message: action.payload.message,
        success: action.payload.success,
      };
    case 'close':
      return toastDefualtState;
    default:
      return toastDefualtState;
  }
};

const initialState = { progress: 0 };
const reducer = (state, action) => {
  switch (action.type) {
    case STATE.REQUESTING:
      return {
        progress: STATE.REQUESTING,
      };
    case STATE.SUCCESS:
      return {
        progress: STATE.SUCCESS,
        url: action.payload,
      };
    case STATE.FAILED:
      return {
        progress: STATE.FAILED,
        message: action.payload,
      };
    case STATE.INITIAL:
      return {
        progress: STATE.INITIAL,
      };

    default:
      throw new Error('Unexpected action');
  }
};

const Room = () => {
  const { isMobile } = useDeviceDetect();
  const theme = {
    container: {
      width: isMobile ? 'unset' : '600px',
      alignSelf: isMobile ? 'unset' : 'center',
      margin: 24,
    },
  };
  const useStyles = makeStyles(() => theme);
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [toaststate, dispatchToast] = useReducer(
    toastReducer,
    toastDefualtState
  );
  const [isValidLink, setValid] = useState(true);
  useEffect(() => {
    const clientID = new URLSearchParams(window.location.search).get('id');
    if (window.location.host.includes('botmodule') && clientID == null) {
      alert(t('contactSupport'));
      setValid(false);
    }
  }, []);
  if (!isValidLink) {
    return null;
  }
  if (isMobile === undefined) {
    return null;
  }
  const handleClose = () => {
    dispatchToast({ type: 'close' });
  };

  const onClickResetButton = async () => {
    dispatch({ type: STATE.REQUESTING });
    try {
      const { url } = await getShortenUrl({
        url: `${window.location.origin}/room/${uuidv4()}?openExternalBrowser=1`,
        expiration: new Date().getTime() + 24 * 60 * 60 * 1000,
      });
      dispatch({
        type: STATE.SUCCESS,
        payload: url,
      });
    } catch (error) {
      dispatch({ type: STATE.FAILED, payload: error.message });
    }
  };

  const onClickButton = async () => {
    if (state.progress === STATE.SUCCESS) {
      copy(state.url);
      dispatchToast({
        type: 'open',
        payload: { message: t('copiedSuccessfully'), success: true },
      });
      return;
    }
    dispatch({ type: STATE.REQUESTING });
    try {
      const { url } = await getShortenUrl({
        url: `${window.location.origin}/room/${uuidv4()}?openExternalBrowser=1`,
        expiration: new Date().getTime() + 24 * 60 * 60 * 1000,
      });
      dispatch({
        type: STATE.SUCCESS,
        payload: url,
      });
    } catch (error) {
      dispatch({ type: STATE.FAILED, payload: error.message });
    }
  };

  const onSendMessage = async ({ phone, message }) => {
    try {
      await sendMessage({
        phone,
        message,
      });
      dispatchToast({
        type: 'open',
        payload: { message: t('sent'), success: true },
      });
    } catch (error) {
      dispatchToast({
        type: 'open',
        payload: {
          message: `${t('sendError')} - ${error.message}`,
          success: false,
        },
      });
    }
  };
  return (
    <Layout background="white">
      <Container className={classes.container}>
        <MenuTitle>{t('issueMeetingLink')}</MenuTitle>
        <Description>
          {t('generateVideoConference')}
          <A
            href="https://www.vonage.com/resources/articles/what-is-cpaas-and-why-should-you-care/"
            target="_blank"
          >
            Vonage
          </A>
          {t('solutionsCompliance')}
          <A
            href="https://support.tokbox.com/hc/en-us/articles/360029411612-How-does-TokBox-ensure-secure-transmission-of-PHI-"
            target="_blank"
          >
            PCI
          </A>{' '}
          &{' '}
          <A
            href="https://www.vonage.com/resources/articles/how-achieve-hipaa-compliant-cloud-technology/"
            target="_blank"
          >
            HIPAA
          </A>
          {t('compliance')}
        </Description>
        {renderActions(state, onClickButton, onClickResetButton, isMobile)}
        <MessageSender
          onSend={onSendMessage}
          message={t('invitePatients')}
          url={state.url || ''}
        ></MessageSender>
        <Toast
          open={toaststate.open}
          onClose={handleClose}
          message={toaststate.message}
        ></Toast>
      </Container>
    </Layout>
  );
};

export default Room;
