import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { useDeviceDetect } from '../../utils/device';

const getTheme = (isMobile) => {
  return {
    snackbar: {
      bottom: isMobile ? '62px' : 'unset',
      top: isMobile ? 'unset' : '100px',
    },
    snackbarBehavior: {
      vertical: isMobile ? 'bottom' : 'top',
    },
    alert: {
      width: isMobile ? '100%' : '500px',
    },
  };
};

function Toast({ open, onClose, message }) {
  const { isMobile } = useDeviceDetect();
  const theme = getTheme(isMobile);
  const useStyles = makeStyles(() => theme);
  const classes = useStyles();
  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{
        vertical: theme.snackbarBehavior.vertical,
        horizontal: 'center',
      }}
      autoHideDuration={2000}
      key={`snackbar`}
      open={open}
      onClose={onClose}
    >
      <Alert
        classes={{
          root: classes.alert,
        }}
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity="success"
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

Toast.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Toast;
