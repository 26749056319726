import React, { useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../../components/1TalkDesign/Buttons';
import TextField from '@material-ui/core/TextField';
import { t } from 'src/i18n/config';

const Container = styled.div``;

const MButton = styled(Button)`
  border-radius: 4px;
`;

const PhoneContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: flex-end;
`;

const EditSection = styled.div`
  margin-top: 8px;
`;

const TextArea = styled.textarea`
  width: -webkit-fill-available;
  height: 80px;
  border: 1;
  padding: 16px;
  font-size: 1rem;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.colors.textPrimary};
`;

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateURL': {
      const { url } = action.payload;
      // first new url
      if (state.url === '' && url !== '') {
        return {
          ...state,
          message: `${state.message}\n${url}`,
          url,
        };
      }

      // not found link in message
      if (!state.message.includes(state.url)) {
        return {
          ...state,
          message: `${state.message}\n${url}`,
          url,
        };
      }

      return {
        ...state,
        message: state.message.replace(state.url, url),
        url,
      };
    }
    case 'onChangeMessage': {
      const { message } = action.payload;
      return {
        ...state,
        message,
      };
    }
    default:
      return state;
  }
};

function MessageSender({ onSend, message: defaultMessage, url: defaultURL }) {
  const [state, dispatch] = useReducer(reducer, {
    message: defaultMessage,
    url: defaultURL,
  });
  useEffect(() => {
    if (defaultURL !== '') {
      dispatch({
        type: 'updateURL',
        payload: {
          url: defaultURL,
        },
      });
    }
  }, [defaultURL]);
  const [phone, setPhone] = useState('');
  const onChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const onClick = () => {
    onSend({
      phone,
      message: state.message,
    });
  };
  const onMessgeChange = (e) => {
    dispatch({
      type: 'onChangeMessage',
      payload: {
        message: e.target.value,
      },
    });
  };
  return (
    <Container>
      <PhoneContainer>
        <TextField
          error={phone === ''}
          label={t('phoneNumber')}
          value={phone}
          onChange={onChangePhone}
        ></TextField>
        <MButton onClick={onClick}>{t('send')}</MButton>
      </PhoneContainer>

      <EditSection>
        <TextArea value={state.message} onChange={onMessgeChange}></TextArea>
      </EditSection>
    </Container>
  );
}

MessageSender.propTypes = {
  onSend: PropTypes.func,
  url: PropTypes.string,
};

export default MessageSender;
