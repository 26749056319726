export const selectAndCopyInNode = (refNode) => {
  if (document.selection) {
    // IE
    const range = document.body.createTextRange();
    range.moveToElementText(refNode);
    range.select().createTextRange();
    document.execCommand('copy');
  } else if (window.getSelection) {
    const range = document.createRange();
    range.selectNode(refNode);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
  }
};

export const copy = (text) => {
  const copiedText = document.createElement('input');
  document.body.appendChild(copiedText);
  copiedText.setAttribute('value', text);
  copiedText.select();
  document.execCommand('copy');
  document.body.removeChild(copiedText);
};
